import { useNavigate } from "react-router-dom";
import "./logo.css";

function Logo({ logoSrc, shadow, play }) {
  const navigate = useNavigate();
  // const shadowStyle = {
  //   filter: `drop-shadow(5px -5px 7px ${shadow})`,
  // };
  return (
    <section className="logo-container">
      <button
        className="logo-button"
        aria-label="Go to Home Page"
        onClick={() => {
          play();
          navigate("/");
        }}
      >
        <svg
          alt="Ajay Ghimire - Software Developer Logo"
          className="logo shadow"
          width="355.3855322906155"
          height="338.64140625"
          viewBox="0 -23 208 198.20000000000002"
        >
          <defs id="SvgjsDefs15734"></defs>
          <g
            id="SvgjsG15735"
            featurekey="rootContainer"
            transform="matrix(0.8,0,0,0.8,3,-23)"
            fill="#fdd612"
            opacity="1.0"
          >
            <rect y="0" height="1" width="1" opacity="0"></rect>
            <rect y="214" width="200" height="5"></rect>
          </g>
          <g
            id="SvgjsG15736"
            featurekey="HdFLvg-0"
            transform="matrix(-0.3634547276954913,0,0,0.3634547276954913,210.44554218315747,110.69582152215152)"
            fill="#fbd411"
            opacity="1.0"
          >
            <g xmlns="http://www.w3.org/2000/svg">
              <g>
                <g>
                  <g>
                    <path d="M26.873,20.334c-4.741-0.014-9.381-1.263-13.263,2.11c-1.87,1.624-2.299,4.311-2.578,6.603      c-0.631,5.161,3.508,11.083-3.364,13.324C6.874,42.63,6.32,43.844,7.116,44.46c1.217,0.941,2.534,1.58,3.881,2.321      c1.541,0.85,1.672,7.571,1.83,8.996c0.518,4.645-2.014,10.145-0.706,14.59c1.439,4.895,10.028,7.739,14.291,4.704      c1.302-0.927,0.055-3.096-1.262-2.158c-3.014,2.146-10.304-0.182-10.696-4.099c-0.361-3.601,1.004-7.689,0.977-11.355      c-0.021-2.898-0.081-7.211-0.959-9.933c-0.908-2.812-3.42-3.157-5.588-4.834c-0.184,0.696-0.367,1.393-0.552,2.089      c3.796-1.237,5.835-2.769,5.909-6.976c0.086-4.918-3.096-12.596,3.484-14.983c2.703-0.981,6.338,0.004,9.147,0.012      C28.485,22.839,28.485,20.339,26.873,20.334L26.873,20.334z"></path>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M69.127,22.834c2.42-0.007,4.821-0.306,7.241-0.291c7.298,0.046,6.105,8.637,5.521,13.517      c-0.596,4.986,1.211,7.233,5.778,8.722c-0.185-0.696-0.368-1.393-0.552-2.089c-1.566,1.212-4.622,1.843-5.313,3.983      c-0.911,2.822-0.803,6.146-1.131,9.102c-0.444,3.987,0.4,7.833,0.836,11.765c0.506,4.552-7.022,7.96-10.659,5.371      c-1.316-0.938-2.563,1.231-1.262,2.158c4.263,3.035,12.852,0.19,14.291-4.704c0.403-1.37,0.155-3.07-0.058-4.448      c-0.528-3.418-0.861-6.683-0.648-10.142c0.141-2.297,0.593-4.489,0.687-6.79c0.101-2.493,3.391-3.264,5.024-4.527      c0.797-0.616,0.242-1.83-0.552-2.089c-5.858-1.91-3.765-6.868-3.369-11.584c0.202-2.408-0.23-5.367-1.771-7.325      c-3.622-4.6-8.899-3.143-14.064-3.128C67.515,20.339,67.515,22.839,69.127,22.834L69.127,22.834z"></path>
                  </g>
                </g>
              </g>
              <g>
                <g>
                  <g>
                    <path d="M35.27,51.389c-5.728-2.952-9.439,7.214-3.4,8.823c4.333,1.155,7.525-7.248,3.4-9.136      c-1.455-0.666-2.726,1.488-1.262,2.158c1.634,0.748,0.089,4.142-1.229,4.547c-1.23,0.378-1.668-1.66-1.501-2.457      c0.259-1.241,1.433-2.445,2.729-1.777C35.438,54.284,36.703,52.127,35.27,51.389L35.27,51.389z"></path>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M46.629,50.639c-2.154,0.781-3.212,2.804-2.837,5.044c0.332,1.985,1.799,3.742,3.916,3.764      c2.555,0.026,4.729-2.692,4.443-5.131c-0.309-2.632-2.759-4.478-5.347-4.346c-1.604,0.081-1.612,2.582,0,2.5      c1.323-0.067,2.462,0.608,2.838,1.917c0.354,1.228-0.944,2.57-2.141,2.558c-0.77-0.008-1.136-1.135-1.263-1.711      c-0.195-0.884,0.177-1.866,1.054-2.185C48.796,52.504,48.147,50.087,46.629,50.639L46.629,50.639z"></path>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M59.451,51.213c-1.226,1.781-1.3,4.066-0.054,5.859c1.094,1.571,3.086,2.631,5.005,2.063      c2.415-0.715,3.265-3.849,2.676-6.033c-0.812-3.01-4.29-4.313-6.866-2.65c-1.35,0.87-0.098,3.035,1.262,2.158      c3.29-2.123,4.363,3.976,1.991,4.185c-0.834,0.073-1.651-0.564-2.107-1.284c-0.644-1.016-0.393-2.099,0.252-3.036      C62.526,51.143,60.358,49.895,59.451,51.213L59.451,51.213z"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g
            id="SvgjsG15737"
            featurekey="VomJeJ-0"
            transform="matrix(9.48166895661035,0,0,9.48166895661035,-3.982297163958199,-53.09734977398762)"
            fill="#fdd612"
            opacity="1.0"
          >
            <path d="M8.02 15 c-0.08 -1.46 -0.74 -2.32 -2.26 -2.32 c-0.42 0 -0.78 0.06 -1.08 0.18 c-0.92 0.42 -1.26 1.2 -1.26 2.16 c0 0.32 0.04 0.62 0.14 0.88 c0.3 1.04 1.2 1.42 2.2 1.42 c1.52 0 2.26 -0.82 2.26 -2.32 z M11.82 20 l-3 0 c-0.2 -0.5 -0.38 -1 -0.5 -1.52 c-0.68 1.12 -1.72 1.62 -3 1.62 c-2.86 0 -4.9 -2.4 -4.9 -5.14 c0 -3.16 2.36 -5.06 5.34 -5.06 c3.18 0 5.18 2.04 5.26 5.1 c0.02 0.26 0.02 0.56 0.02 0.92 c0 1.4 0.22 2.8 0.78 4.08 z M15.219999999999999 25 l-3 0 c0.56 -1.26 0.8 -2.7 0.8 -4.06 l0 -10.94 l3 0 l0 10.92 c0 1.38 -0.24 2.82 -0.8 4.08 z M14.52 9.06 c-1.02 0 -1.74 -0.72 -1.74 -1.74 c0 -1.04 0.72 -1.72 1.74 -1.72 c1.04 0 1.72 0.68 1.72 1.72 c0 1.06 -0.66 1.74 -1.72 1.74 z"></path>
          </g>
        </svg>
        {/* 
        <img
          src={logoSrc}
          alt="Ajay Ghimire - Software Developer Logo"
          className="logo shadow"
          style={{ filter: `drop-shadow(5px -5px 7px ${shadow})` }}
        /> */}
      </button>
    </section>
  );
}

export default Logo;
